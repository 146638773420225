import React from 'react';

import Page from './Page';

class HomePage extends Page {
    renderPage() {
        return (
            "Home"
        );
    }
    
    getName() {
        return "Home";
    }
}

export default HomePage;