import React from 'react';

class Page extends React.Component {
    render() {
        return (
            <div className={"page page-" + this.getName() }>
                {this.renderPage()}
            </div>
        )
    }
    
    renderPage() {
        throw new Error("renderPage not overwritten");
    }
    
    getName() {
        throw new Error("getName not overwritten");
    }
}

export default Page;