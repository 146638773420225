import React from 'react';

import {HashRouter as Router, Route, Routes} from 'react-router-dom';

import Container from 'react-bootstrap/Container';

import ContactsPage from '../pages/ContactsPage';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';

class Content extends React.Component {

    render() {
        return (
            <Container>
                <Router>
                    <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/projects' element={<HomePage />} />
                        <Route path='/contacts' element={<ContactsPage />} />
                        <Route path='*' element={<NotFoundPage />} />
                    </Routes>
                </Router>
            </Container>
        );
    }
}

export default Content;