import React from 'react';

import Page from './Page';

class ContactsPage extends Page {
    renderPage() {
        return (
            "E-mail: tipisoftsolutions@gmail.com"
        );
    }
    
    getName() {
        return "Contacts";
    }
}

export default ContactsPage;