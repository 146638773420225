import React from 'react';

import Page from './Page';

class NotFoundPage extends Page {
    renderPage() {
        return (
            "Not found"
        );
    }
    
    getName() {
        return "NotFound";
    }
}

export default NotFoundPage;