import Content from './js/components/Content';
import Menu from './js/components/Menu';

function App() {
  return (
    <div className="App">
        <Menu />
        <Content />
    </div>
  );
}

export default App;
